exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hez-circ-supply-jsx": () => import("./../../../src/pages/hez-circ-supply.jsx" /* webpackChunkName: "component---src-pages-hez-circ-supply-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-join-us-position-jsx": () => import("./../../../src/pages/join-us/position.jsx" /* webpackChunkName: "component---src-pages-join-us-position-jsx" */),
  "component---src-pages-legal-disclaimer-jsx": () => import("./../../../src/pages/legal-disclaimer.jsx" /* webpackChunkName: "component---src-pages-legal-disclaimer-jsx" */),
  "component---src-pages-media-inquiries-jsx": () => import("./../../../src/pages/media-inquiries.jsx" /* webpackChunkName: "component---src-pages-media-inquiries-jsx" */),
  "component---src-pages-payment-network-jsx": () => import("./../../../src/pages/payment-network.jsx" /* webpackChunkName: "component---src-pages-payment-network-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-project-jsx": () => import("./../../../src/pages/project.jsx" /* webpackChunkName: "component---src-pages-project-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-wallet-jsx": () => import("./../../../src/pages/wallet.jsx" /* webpackChunkName: "component---src-pages-wallet-jsx" */)
}

